export const CATEGORIES: any = {
  category: {
    au: 'category',
    de: 'kategorie',
    en: 'category',
    es: 'categoria',
    fr: 'categorie',
    nl: 'categorie',
    'pt-br': 'categoria',
    us: 'category',
  },
};
