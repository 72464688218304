// ... Lib
import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';

// ... Utilities
import typeCard from '@/utilities/types/Card';
import typeCards from '@/utilities/types/Cards';
import typeCategories from '@/utilities/types/Categories';

// ... Components
import CardContent from './CardContent';

// ... Styles
import styles from './Card.module.scss';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  card: typeCard[];
  categories?: typeCategories[];
  customise: typeCards['customise'];
  heading: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  stretch?: boolean;
  type?: string;
  withLearnMore?: boolean;
}

const Card = (props: CardProps) => {
  const { withLearnMore, card, customise, stretch } = props;

  const { push } = useRouter();

  const isLinkCard = withLearnMore && customise.link?.url;

  const classNames = cn(
    styles.root,
    customise.classNames,
    styles[customise.alignment],
    {
      [styles.stretch]: stretch,
    },
  );

  const cardLinks = card
    .filter((card) => card.links?.some((link) => link?.style === 'link'))
    .map(
      (card) =>
        card.links?.find((link) => link?.style === 'link')?.link?.url || '',
    );

  const isOldLinkCard = card.some((card) =>
    card.links?.some((link) => link?.style === 'link'),
  );

  const onCardClick = (cardLinks: string[]) => {
    isOldLinkCard && cardLinks[0] ? push(cardLinks[0]) : null;
  };

  if (isLinkCard) {
    return (
      <Link href={isLinkCard} className={styles.cardLink}>
        <article className={classNames}>
          <CardContent {...props} />
        </article>
      </Link>
    );
  }

  return (
    <article
      className={cn(classNames, {
        [styles.cardLink]: isOldLinkCard,
      })}
      onClick={() => onCardClick(cardLinks)}
    >
      <CardContent {...props} />
    </article>
  );
};

export default Card;
