// ... Lib
import { Fragment } from 'react';
import dynamic from 'next/dynamic';
import DOMPurify from 'isomorphic-dompurify';
import cn from 'classnames';

// ... Utilities
import { useLocale } from '@/utilities/contexts/Locale';
import { CATEGORIES } from '@/utilities/constants/Categories';
import { LEVER } from '@/utilities/constants/Lever';

// ... Components
import Badge from '@/components/atoms/Badge/Badge';
import Calendar from '@/icons/maggie/Calendar';
import Clock from '@/icons/maggie/Clock';
import ArrowRight from '@/icons/maggie/ArrowRight';

// ... Styles
import styles from './Card.module.scss';
import borderColors from '@/styles/modules/BorderColors.module.scss';

// ... Types
import { CardProps } from './Card';

const Button = dynamic(() => import('@/components/atoms/Button'));
const Buttons = dynamic(() => import('@/components/molecules/Buttons'));
const Chip = dynamic(() => import('@/components/atoms/Chip'));
const Highlight = dynamic(() => import('@/components/molecules/Highlight'));
const Image = dynamic(() => import('next/image'));
const Typography = dynamic(() => import('@/components/atoms/Typography'));

const CardContent = (props: CardProps) => {
  const { withLearnMore, card, categories, customise, heading, stretch, type } =
    props;
  const locale = useLocale();

  return (
    <>
      {categories && (
        <ul className={styles.categories}>
          {categories.map((category) => {
            if (!category.parentId) {
              return (
                <li key={category.slug}>
                  <Chip
                    href={`/${type}/${CATEGORIES.category[locale]}/${category.slug}/`}
                  >
                    {category.name}
                  </Chip>
                </li>
              );
            }
          })}
        </ul>
      )}

      {card.map((card, index) => (
        <Fragment key={index}>
          {card.logo && (
            <div className={styles.logo}>
              <Image
                alt={card.logo.altText}
                height={card.logo.mediaDetails.height || 80}
                src={card.logo.mediaItemUrl}
                width={card.logo.mediaDetails.width || 80}
              />
            </div>
          )}
          {card.chips ? (
            <div className={styles.chips}>
              {card.chips.map((chip, index) => (
                <Chip
                  key={index}
                  className={cn(
                    chip?.color
                      ? borderColors[chip.color]
                      : borderColors['charcoal-charlie'],
                    styles.chip,
                  )}
                >
                  {chip.chip}
                </Chip>
              ))}
            </div>
          ) : null}

          {card.chip && (
            <div className={styles.chip}>
              <Chip
                key={index}
                className={cn(
                  card.color
                    ? borderColors[card.color]
                    : borderColors['charcoal-charlie'],
                  styles.chip,
                )}
              >
                {card.chip}
              </Chip>
            </div>
          )}
          {card.editor && (
            <Typography className={styles.editor} xs={3}>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(card.editor),
                }}
              />
            </Typography>
          )}
          {card.description && (
            <Typography className={styles.description} xs={3}>
              {card.description}
            </Typography>
          )}

          {card.highlight && (
            <Highlight
              className={styles.highlight}
              highlight={card.highlight}
            />
          )}
          {card.icon && card.icon.icon && (
            <i
              className={cn(
                card.icon.border && styles.border,
                card.icon.height && styles.iconWithCustomHeight,
                styles.icon,
              )}
            >
              <Image
                alt={card.icon.icon.altText}
                height={card.icon.icon.mediaDetails.height || 80}
                src={card.icon.icon.mediaItemUrl}
                style={{
                  maxHeight: card.icon.height,
                  width: card.icon.height && 'auto',
                }}
                width={card.icon.icon.mediaDetails.width || 80}
              />
            </i>
          )}
          {card.image &&
            (customise.link && customise.link.url ? (
              <Button
                className={styles.image}
                external={customise.link.target ? true : false}
                href={customise.link.url}
              >
                <Image
                  alt={card.image.altText}
                  height={card.image.mediaDetails.height || 172}
                  src={card.image.mediaItemUrl}
                  width={card.image.mediaDetails.width || 345}
                />
              </Button>
            ) : (
              <div className={styles.image}>
                <Image
                  alt={card.image.altText}
                  height={card.image.mediaDetails.height || 172}
                  src={card.image.mediaItemUrl}
                  width={card.image.mediaDetails.width || 345}
                />
              </div>
            ))}
          {!withLearnMore && card.links && (
            <div className={styles.links}>
              <Buttons buttons={card.links} />
            </div>
          )}
          {card.title && (
            <Typography
              className={cn(
                card?.titleOptions && styles.titleInline,
                styles.title,
              )}
              family="poppins"
              tag={heading}
              xs={4}
              md={5}
            >
              {card?.titleOptions &&
                card.titleOptions.icon &&
                card.titleOptions.icon.icon && (
                  <i
                    className={cn(
                      card.titleOptions.icon.border && styles.border,
                      styles.icon,
                    )}
                  >
                    <Image
                      alt={card.titleOptions.icon.icon.altText}
                      height={
                        card.titleOptions.icon.icon.mediaDetails.height || 80
                      }
                      src={card.titleOptions.icon.icon.mediaItemUrl}
                      style={{
                        maxHeight: card.titleOptions.icon.height,
                        width: card.titleOptions.icon.height && 'auto',
                      }}
                      width={
                        card.titleOptions.icon.icon.mediaDetails.width || 80
                      }
                    />
                  </i>
                )}
              {card.title}
              {card?.titleOptions &&
                card.titleOptions.badge &&
                card.titleOptions.badge.text && (
                  <Badge
                    label={card.titleOptions.badge.text}
                    backgroundColor={
                      card.titleOptions.badge.background
                        ? card.titleOptions.badge.background
                        : 'purple-rain'
                    }
                    style="classic"
                  />
                )}
            </Typography>
          )}
          {card?.date &&
            card?.date?.dateStart &&
            (() => {
              const eventDate: string | undefined = card?.date?.dateStart
                ? new Date(card?.date.dateStart).toLocaleDateString('en-AU', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })
                : '';

              return (
                <div className={styles.eventDetails}>
                  <div className="dates">
                    <ul>
                      <li>
                        <i>
                          <Calendar width={20} height={22} />
                        </i>
                        <Typography tag="span" family="noto-sans" xs={3}>
                          {eventDate}
                        </Typography>
                      </li>
                      {card?.date?.timeStart && (
                        <li>
                          <i>
                            <Clock width={20} height={20} />
                          </i>
                          <Typography tag="span" family="noto-sans" xs={3}>
                            {card?.date.timeStart}
                          </Typography>
                          {card?.date?.timeEnd && (
                            <Typography tag="span" family="noto-sans" xs={3}>
                              {` - ${card.date.timeEnd}`}
                            </Typography>
                          )}
                          {card?.date?.gtm && (
                            <Typography tag="span" family="noto-sans" xs={3}>
                              {` (${card.date?.gtm})`}
                            </Typography>
                          )}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              );
            })()}
        </Fragment>
      ))}
      {withLearnMore && (
        <div className={styles.learnMore}>
          <Typography xs={3}>{LEVER.learnMore[locale ?? 'en']}</Typography>
          <ArrowRight />
        </div>
      )}
    </>
  );
};

export default CardContent;
